(function ($) {
    function domina_dynamic_calendar(){
        var html_element = $('html'),
            btns = $('#open_dyna_cal, #open_mobile_dyna_cal'),
            calendar_wrapper = $('#dyncal_wrapper'),
            slideshow_container = $('#slideshow_wrapper');
        btns.click(function () {
            if(slideshow_container.length > 0 && !html_element.hasClass('blog')){
                slideshow_container.find('.slideshow').slick('slickPause');
            }
            if (!calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.addClass('visible');
                html_element.addClass('no-scroll');
            }
        });

        $('.close_calendar').click(function () {
            if (calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.removeClass('visible');
                html_element.removeClass('no-scroll');
            }
            if(slideshow_container.length > 0 && !html_element.hasClass('blog')){
                slideshow_container.find('.slideshow').slick('slickPlay');
            }
        });
    }

    $(document).ready(function (){
        // domina_dynamic_calendar();

        window.date_format = 'dd/mm/yy';
        var today = new Date(),
            number_month = ($(window).width() <= 768) ? 1 : 2,
            submit_button = $('#idForm'),
            cur = -1,
            prv = -1;
        function init_default_values(){
            //update checkin dates
            var thisday = new Date();
            $('.arriving-tab .date').text($.datepicker.formatDate(date_format, thisday));

            //update checkout dates
            thisday.setDate(thisday.getDate()+1);
            $('.departure-tab .date').text($.datepicker.formatDate(date_format, thisday));

            //Adults
            //Default  2 adults
            $('.bf_select-adults').val(2);
            $('input[name=adults]').val($('.bf_select-adults').val());

            // Children
            $('input[name=children]').val($('.bf_select-children').val());

            updates_nights($('input[name=date]').val(),$('input[name=departure]').val());
        }

        function updates_nights(checkin_date,checkout_date){

            var date_in = new Date(checkin_date),
                date_out = new Date(checkout_date),
                timeDiff = Math.abs(date_out.getTime() - date_in.getTime()),
                numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

            $('input[name=nights]').val(numberOfNights);

        }


        function update_selects(arrival_date, departure_date) {
            var arrival = new Date(arrival_date);
            var fromday = (arrival.getDate() < 10 ? '0' : '') + arrival.getDate();
            var frommonth = (arrival.getMonth() < 9 ? '0' : '') + (arrival.getMonth() +1);
            var fromyear = arrival.getFullYear();

            var departure = new Date(departure_date);
            var today = (departure.getDate() < 10 ? '0' : '') + departure.getDate();
            var tomonth = (departure.getMonth() < 9 ? '0' : '') + (departure.getMonth() +1);
            var toyear = departure.getFullYear();

            var checkindate = fromyear+'-'+frommonth+'-'+fromday;
            var checkout = toyear+'-'+tomonth+'-'+today;

            // I update the hidden fields for the QS form
            $('input[name=date]').val(checkindate);

            updates_nights(checkindate,checkout);

        }

        function childrenages(){
            var child_sels = $('.children-ages-tab').not('.hidden').find('select');
            var values = new Array;
            child_sels.each(function(k){
                var val = $(this).val();
                if(val!== '0'){
                    values.push(val);
                }
            });
            $('input[name=children-age]').val(values.toString().replace(',',';'));
        }

        $("#inline-datepicker").datepicker({
            minDate:today,
            firstDay: 1,
            numberOfMonths: number_month,
            beforeShowDay: function ( date ) {
                var _class = '';
                if(date.getTime() === Math.min(prv, cur)) {
                    _class = 'date-range-selected checkin-date';
                } else if(date.getTime() === Math.max(prv, cur)) {
                    _class = 'date-range-selected checkout-date';
                } else if(date.getTime() > Math.min(prv, cur) && date.getTime() < Math.max(prv, cur)) {
                    _class = 'date-range-selected';
                }
                return [true, _class];
            },
            onSelect: function ( dateText, inst ) {
                var arrival_date;
                var departure_date;
                prv = cur;
                cur = (new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay)).getTime();
                if ( prv === -1 || prv === cur ) {
                    prv = cur;
                    arrival_date = $.datepicker.formatDate(date_format, new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay), {} );
                    $('.arriving-tab .date').text(arrival_date);
                    $('.departure-tab .date').text('...');
                    update_selects(cur, cur+86400000);
                }
                else {
                    arrival_date = $.datepicker.formatDate(date_format, new Date(Math.min(prv,cur)), {} );
                    departure_date = $.datepicker.formatDate(date_format, new Date(Math.max(prv,cur)), {} );
                    $('.arriving-tab .date').text(arrival_date);
                    $('.departure-tab .date').text(departure_date);
                    update_selects( Math.min(prv,cur) , Math.max(prv,cur) );
                }
            }
        });

        init_default_values();

        var adults_select = $('.bf_select-adults'),
        children_select = $('.bf_select-children');

        //On change adults select
        adults_select.on('change',function(){
            var adults_value = $(this).val();
            $('input[name=adults]').val(adults_value);
        });

        //On change children number
        children_select.on('change', function(){
            var children_value = $(this).val();
            //Update input hidden
            $('input[name=children]').val(children_value);
            if(children_value !== '0'){
                $('.children-ages-tab').addClass('hidden');
                for(i= 1; i <= children_value; i++) {
                    var single_child_age = $('#single_children_age_'+i);

                    single_child_age.removeClass('hidden');
                    var single_child_age_selector = single_child_age.not('.hidden').find('select');

                    single_child_age_selector.on('change',function (){
                        childrenages();
                    });

                    $('input[name=children-age]').prop("disabled", false);
                }
                childrenages();
            } else {
                $('input[name=children-age]').prop("disabled", true);
                $('input[name=children-age]').val('');
                $('.children-ages-tab select').prop('selectedIndex', 0);
                $('.children-ages-tab').addClass('hidden');
            }
        });

        // Promo code
        $('input.promo_code').on('change', function () {
            if($(this).val() !== ''){
                $('input[name=promo-code-plain]').val($(this).val());
                $('input[name=promo-code-plain]').prop("disabled", false);
            }
        });
    });
})(jQuery);